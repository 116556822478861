import {Database} from "@/data/types/supabase/supabase";
import {createClient} from "@supabase/supabase-js";

export const supabaseClient = createClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL || "",
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || ""
);

export const supabaseClientWithServiceRoleKey = createClient<Database>(
 
    process.env.NEXT_PUBLIC_SUPABASE_URL || "",
    process.env.NEXT_PUBLIC_SUPABASE_SERVICE_ROLE_KEY || ""
 
);
